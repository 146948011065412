import React from 'react'
import PropTypes from 'prop-types'
import './CustomInput.scss'
const CustomInput = props => {
  const {
    value = '',
    handleChange = () => {},
    type = 'rounded',
    name = '',
    placeholder = '',
    isPassword = false,
    onKeyPress = () => {},
  } = props

  return (
    <div className={`${type}Container`}>
      <input
        name={name}
        value={value}
        onChange={handleChange}
        className={`${type}Input`}
        placeholder={placeholder}
        type={isPassword ? 'password' : 'text'}
        onKeyPress={onKeyPress}
      />
    </div>
  )
}

// IT'S A MUST
CustomInput.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string,
  isPassword: PropTypes.bool,
  handleChange: PropTypes.func,
  onKeyPress: PropTypes.func,
}

export default CustomInput
