import React, { useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import CustomTable from 'components/CustomTable/CustomTable'

import { REST } from 'utils/axiosHelper'
// STYLE
import './Collections.scss'
import loggedIn from 'utils/loggedIn'

const Collections = props => {
  const { history } = props
  const [listOfData, setListOfData] = useState([])
  const [elements, setElements] = useState(null)
  const [size, setSize] = useState(5)
  const [page, setPage] = useState(0)

  useEffect(() => {
    getAllActiveProjects()
  }, [])

  useEffect(() => {
    getAllActiveProjects()
  }, [page, size])

  const getAllActiveProjects = async () => {
    await REST.get(`/api/collect/project/getAllActiveProjects?page=${page}&size=${size}`)

      .then(answer => {
        setListOfData(answer.data.content)
        setElements(answer.data.totalElements)
      })
      .catch(err => {
        console.log(`err in getAllActiveProjects`, err)
      })
  }

  return !loggedIn() ? (
    <Redirect to="/login" />
  ) : (
    <div className="collectionsContainer">
      <div className="collectionsTitleContainer">
        <h1 className="collectionsTitle">List of Collections</h1>
      </div>

      <CustomTable
        history={history}
        tableData={listOfData}
        elements={elements}
        setSize={setSize}
        page={page}
        setPage={setPage}
      />
    </div>
  )
}

Collections.propTypes = {
  className: PropTypes.string, // if it's mandatory prop add ".isRequired"
}

export default Collections
