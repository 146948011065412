import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import moment from 'moment'
import React from 'react'
import './CustomTable.scss'
import TablePagination from '@mui/material/TablePagination'
import PropTypes from 'prop-types'
const CustomTable = props => {
  const {
    tableData = {},
    elements = 0,
    setPage = () => {},
    page = 0,
    setSize = () => {},
    history,
  } = props

  const [rowsPerPage, setRowsPerPage] = React.useState(5)

  const handleClick = (id, data, index) => {
    history.push({
      pathname: `/collections/${id}`,

      state: {
        name: tableData[index].artist.name,
        projectName: tableData[index].projectName,
        projectType: tableData[index].projectType,
        title: tableData[index].title,
      },
    })
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setSize(parseInt(event.target.value, 10))
  }

  return (
    <div className="table-container-div">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table" className="table-wrapper">
          <TableHead>
            <TableRow>
              <TableCell>
                <p className="header-content">Artist</p>
              </TableCell>
              <TableCell align="left">
                <p className="header-content">Project name</p>
              </TableCell>
              <TableCell align="left">
                <p className="header-content">Project type</p>
              </TableCell>
              <TableCell align="right">
                <p className="header-content">Date</p>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData?.map((row, index) => (
              <TableRow
                onClick={() => handleClick(row.id, row, index)}
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <p className="table-content">{row && row.artist && row.artist.name}</p>
                </TableCell>
                <TableCell component="th" scope="row">
                  <p className="table-content">{row.projectName}</p>
                </TableCell>
                <TableCell component="th" scope="row">
                  <p className="table-content">{row.projectType}</p>
                </TableCell>
                <TableCell align="right">
                  <p className="table-content">{moment(row.creationDate).format('DD-MM-YYYY')}</p>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={elements}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </div>
  )
}

CustomTable.propTypes = {
  elements: PropTypes.number,
  page: PropTypes.number,
  tableData: PropTypes.object,
  setPage: PropTypes.func,
  setSize: PropTypes.func,
  history: PropTypes.any,
}
export default CustomTable
