import React from 'react'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'

// COMPONENTS
import Routes from 'utils/Routes'

const App = () => {
  const browserHistory = createBrowserHistory()
  return (
    <Router history={browserHistory}>
      <Routes browserHistory={browserHistory} />
    </Router>
  )
}

export default App
