import axios from 'axios'
import AuthHeader from './AuthHeader'

export const beURL = 'https://backend-two.katastic.com/jointoo'

export const REST = axios.create({
  baseURL: beURL,
  timeout: 10000,
  headers: { 'X-engage-initiator': 'frontend', ...AuthHeader() },
})
