import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import logo from 'assets/png/logo.png'
import store from 'store'
import { Redirect } from 'react-router-dom'
// Components
import CustomInput from 'components/CustomInput/CustomInput'
import CustomButton from 'components/CustomButton/CustomButton'
import loggedIn from 'utils/loggedIn'

// STYLE
import './LogIn.scss'

const LogIn = props => {
  const { history } = props

  const [loginState, setLoginState] = useState({})
  const [enableButton, setEnableButton] = useState(false)
  const [wrongCredentials, setWrongCredentials] = useState(false)

  useEffect(() => {
    setWrongCredentials(false)

    if (loginState['userName'] && loginState['password']) {
      setEnableButton(true)
    } else {
      setEnableButton(false)
    }
  }, [loginState])

  const handleChange = event => {
    setLoginState(loginState => ({
      ...loginState,
      [event.target.name]: event.target.value,
    }))
  }
  const handleSubmit = () => {
    if (enableButton) {
      if (loginState['userName'] === 'admin' && loginState['password'] === 'admin') {
        store.set('loggedIn', true)
        history.push('/collections')
      } else {
        setWrongCredentials(true)
      }
    }
  }

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      handleSubmit()
    }
  }

  return loggedIn() ? (
    <Redirect to="/collections" />
  ) : (
    <div className="loginContainer">
      <div className="logoContainer">
        <img src={logo} alt="" />
      </div>
      <h1 className="pageTitle">Collections</h1>
      <div className="inputContainer">
        <CustomInput
          value={loginState['userName']}
          name={'userName'}
          handleChange={handleChange}
          type="rounded"
          placeholder="User Name"
          onKeyPress={handleKeyPress}
        />
        <CustomInput
          value={loginState['password']}
          name={'password'}
          handleChange={handleChange}
          type="rounded"
          placeholder="Password"
          isPassword={true}
          onKeyPress={handleKeyPress}
        />
      </div>
      <div className="buttonContainer">
        {wrongCredentials ? (
          <h3 className="wrongCredentials">User name or password are invalid</h3>
        ) : (
          <div className="wrongCredentials" />
        )}
        <CustomButton
          name={'submit'}
          handleChange={handleSubmit}
          type="rounded"
          text="Enter"
          isEnabled={enableButton}
        />
      </div>
    </div>
  )
}

// IT'S A MUST
LogIn.propTypes = {
  className: PropTypes.string, // if it's mandatory prop add ".isRequired"
}

export default LogIn
