import React from 'react'
import PropTypes from 'prop-types'
import './CustomModal.scss'
import Modal from 'react-modal'
import CustomInput from 'components/CustomInput/CustomInput'
import CustomButton from 'components/CustomButton/CustomButton'
import AmountInput from 'components/AmountInput/AmountInput'
const CustomModal = props => {
  const {
    modalIsOpen = false,
    closeModal = () => {},
    handleSubmitAmount = () => {},
    handleAmountChange = () => {},
    handleKeyPress = () => {},
    setAmountValue = () => {},
    isSubmitAmountEnabled = false,
    amountValue = null,
  } = props

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#0d0806',
      borderColor: '#ff3366',
      borderWidth: '1px',
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
    },
  }
  console.log(`amountValue`, amountValue)
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      contentLabel="Amount"
      style={customStyles}
    >
      <div className="modal-container">
        <div className="modal-title-container">
          <p className="modal-title-text">Enter Amount</p>
        </div>
        <div className="modal-custom-input-container">
          <AmountInput
            amountValue={amountValue}
            setAmountValue={() => setAmountValue()}
            name={'amount'}
            handleChange={handleAmountChange}
            type="rounded"
            placeholder="Amount"
            onKeyPress={handleKeyPress}
          />
        </div>
        <div className="modal-custom-button-container">
          <CustomButton
            name={'submitAmount'}
            handleChange={handleSubmitAmount}
            type="rounded"
            text="Submit"
            isEnabled={isSubmitAmountEnabled}
          />
        </div>
      </div>
    </Modal>
  )
}

// IT'S A MUST
CustomModal.propTypes = {
  amountValue: PropTypes.number,
  modalIsOpen: PropTypes.bool,
  isSubmitAmountEnabled: PropTypes.bool,
  closeModal: PropTypes.func,
  handleSubmitAmount: PropTypes.func,
  handleKeyPress: PropTypes.func,
  handleAmountChange: PropTypes.func,
  setAmountValue: PropTypes.func,
}

export default CustomModal
