import React from 'react'
import NumberFormat from 'react-number-format'
import './AmountInput.scss'

const AmountInput = props => {
  const {
    currency = '',
    amountValue = null,
    handleChange = () => {},
    name = '',
    placeholder = '',
    onKeyPress = () => {},
  } = props

  return (
    <div className={'amount-input-wrapper'}>
      <div className={'amount-container'}>
        <NumberFormat
          thousandSeparator={true}
          className={'number-format'}
          style={{
            backgroundColor: 'transparent',
            color: 'white',
            width: '100%',
            border: 'none',
            outline: 'none',
            paddingLeft: '5px',
            paddingRight: '5px',
            textAlign: 'end',
          }}
          id="percentage"
          name={name}
          placeholder={placeholder}
          prefix={'$ '}
          onKeyPress={onKeyPress}
          // suffix={" %"}
          value={amountValue}
          onValueChange={values => {
            const { formattedValue, value, floatValue } = values

            handleChange(floatValue)
          }}
        />
      </div>
      <div className={'currency-label'}>
        <label>{currency}</label>
      </div>
    </div>
  )
}

export default AmountInput
