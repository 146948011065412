import lscache from "lscache";

const accessToken = lscache.get("accessToken");

const AuthHeader = () => {
  if (accessToken) {
    return {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json"
    };
  } else {
    return {};
  }
};

export default AuthHeader;
