import React from 'react'
import './CustomRevenueCard.scss'
import PropTypes from 'prop-types'

const CustomRevenueCard = props => {
  const { cardDetails = {} } = props

  var nf = new Intl.NumberFormat()
  return (
    <div className="card-container-for-user">
      <div className="card-text-container-title">
        <p className="card-text-container-title-value-bold">
          {cardDetails ? cardDetails.user.email : 'No data'}
        </p>
      </div>
      {/* <div className="card-text-container-title">
        <p className="card-text-container-title-text">Birthdate</p>
        &nbsp;
        <p className="card-text-container-title-name">:</p>
        &nbsp;
        <p className="card-text-container-title-value">
          {cardDetails ? cardDetails.user.birthdate : 'No data'}
        </p>
      </div> */}
      <div className="card-text-container-title">
        <p className="card-text-container-title-text">Number Of Units</p>
        &nbsp;
        <p className="card-text-container-title-name">:</p>
        &nbsp;
        <p className="card-text-container-title-value">
          {cardDetails ? nf.format(cardDetails.numberOfUnits) : 'No data'}
        </p>
      </div>
      <div className="card-text-container-title">
        <p className="card-text-container-title-text">Value</p>
        &nbsp;
        <p className="card-text-container-title-name">:</p>
        &nbsp;
        <p className="card-text-container-title-value">
          {cardDetails ? '$' + nf.format(cardDetails.value) : 'No data'}
        </p>
      </div>
      <div className="card-text-container-title">
        <p className="card-text-container-title-text">Wallet Id</p>
        &nbsp;
        <p className="card-text-container-title-name">:</p>
        &nbsp;
        <p className="card-text-container-title-value">
          {cardDetails ? cardDetails.walletId : 'No data'}
        </p>
      </div>

      {/* <div className="card-text-container-title">
        <p className="card-text-container-title-text">Has Platinum</p>
        &nbsp;
        <p className="card-text-container-title-name">-</p>
        &nbsp;
        <p className="card-text-container-title-value">
          {cardDetails.user.platinum ? 'Yes' : 'No'}
        </p>
      </div>
      <div className="card-text-container-title">
        <p className="card-text-container-title-text">Pro Individual</p>
        &nbsp;
        <p className="card-text-container-title-name">-</p>
        &nbsp;
        <p className="card-text-container-title-value">
          {cardDetails.user.proIndividual ? 'Yes' : 'No'}
        </p>
      </div>
      <div className="card-text-container-title">
        <p className="card-text-container-title-text">Pro Entity</p>
        &nbsp;
        <p className="card-text-container-title-name">-</p>
        &nbsp;
        <p className="card-text-container-title-value">
          {cardDetails.user.proEntity ? 'Yes' : 'No'}
        </p>
      </div> */}
    </div>
  )
}

// IT'S A MUST
CustomRevenueCard.propTypes = {
  cardDetails: PropTypes.object,
}

export default CustomRevenueCard
