import React from 'react'
import PropTypes from 'prop-types'
import './CustomButton.scss'

const CustomButton = props => {
  const {
    handleChange = () => {},
    type = 'rounded',
    name = '',
    text = '',
    isEnabled = false,
  } = props

  return (
    <div className={`${type}Container`}>
      <button
        name={name}
        className={!isEnabled ? `${type}ButtonDisabled` : `${type}Button`}
        onClick={handleChange}
      >
        {text}
      </button>
    </div>
  )
}

// IT'S A MUST
CustomButton.propTypes = {
  name: PropTypes.string,
  text: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string,
  isEnabled: PropTypes.bool,
  handleChange: PropTypes.func,
}

export default CustomButton
