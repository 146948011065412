import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { useHistory } from 'react-router-dom'

// COMPONENTS
import Collections from 'pages/Collections/Collections'
import RevenueDistribution from 'pages/RevenueDistribution/RevenueDistribution'
import LogIn from 'pages/LogIn/LogIn'
import NotFound from 'pages/NotFound/NotFound'
import LeftSidebar from 'components/LeftSidebar/LeftSidebar'

const Routes = props => {
  const history = useHistory()

  return (
    <Switch>
      <Route exact path="/login">
        <LogIn history={history} />
      </Route>
      <LeftSidebar history={history}>
        <Route exact path="/">
          <Collections history={history} />
        </Route>
        <Route exact path="/collections">
          <Collections history={history} />
        </Route>
        <Route exact path="/collections/:id">
          <RevenueDistribution history={history} />
        </Route>
      </LeftSidebar>
      <Route path="/404">
        <NotFound />
      </Route>
    </Switch>
  )
}
export default Routes
