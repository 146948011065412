import React from 'react'
import CustomSidebarBtn from 'components/CustomSidebarBtn/CustomSidebarBtn'
import Logo from '../../assets/png/logo.png'
import './Sidebar.scss'
import store from 'store'

const Sidebar = props => {
  const { history } = props

  const handleChange = name => {
    if (name === 'login') {
      store.set('loggedIn', false)
      history.push(`/${name}`)
    } else {
      history.push(`/${name}`)
    }
  }

  return (
    <div className="sidebar-compoenent">
      <div className="img-container">
        <img src={Logo} className="logo-img" alt="" />
      </div>
      <div className="links-container">
        <div className="menu-link">
          <CustomSidebarBtn name={'collections'} text={'Collections'} handleChange={handleChange} />
          {/* <CustomSidebarBtn name={'revenue'} text={'Revenue'} handleChange={handleChange} /> */}
        </div>
        <div className="sign-out">
          <CustomSidebarBtn name={'login'} text={'Sign Out'} handleChange={handleChange} />
        </div>
      </div>
    </div>
  )
}

export default Sidebar
