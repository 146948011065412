import React from 'react';
import './CustomSidebarBtn.scss';
import PropTypes from 'prop-types';

const CustomSidebarBtn = (props) => {
	const { handleChange = () => {},  name = '', text = '' } = props;

	return (
		<div className="btn-container">
			<button
				name={name}
				className={"btn-body"}
				onClick={() => handleChange(name)}
			>
				{text}
			</button>
		</div>
	);
};

// IT'S A MUST
CustomSidebarBtn.propTypes = {
	name: PropTypes.string,
	text: PropTypes.string,
	handleChange: PropTypes.func
};

export default CustomSidebarBtn;
