import React from 'react'
import './LeftSidebar.scss'
import Sidebar from 'components/Sidebar/Sidebar'

const LeftSidebar = props => {
  const { children, history } = props

  return (
    <div className="container">
      <div className="sidebar">
        <Sidebar history={history} />
      </div>
      <div className="children">{children}</div>
    </div>
  )
}

export default LeftSidebar
