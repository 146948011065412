import React from 'react'
import './CustomRevenueCardForUser.scss'
import PropTypes from 'prop-types'
import moment from 'moment'
const CustomRevenueCardForUser = props => {
  const { cardDetails = {} } = props

  var nf = new Intl.NumberFormat()
  return (
    <div className="card-container">
      <div className="card-text-container-title">
        <p className="card-text-container-title-text">Collection Date</p>
        &nbsp;
        <p className="card-text-container-title-name">:</p>
        &nbsp;
        <p className="card-text-container-title-value">
          {' '}
          {moment(cardDetails.collectionDate).format('MMM Do YY')}{' '}
        </p>
      </div>
      <div className="card-text-container-title">
        <p className="card-text-container-title-text">Revenue Amount</p>
        &nbsp;
        <p className="card-text-container-title-name">:</p>
        &nbsp;
        <p className="card-text-container-title-value">${nf.format(cardDetails.amountCollected)}</p>
      </div>
      {/* <div className="card-text-container-title">
        <p className="card-text-container-title-text">Has Platinum</p>
        &nbsp;
        <p className="card-text-container-title-name">-</p>
        &nbsp;
        <p className="card-text-container-title-value">
          {cardDetails.user.platinum ? 'Yes' : 'No'}
        </p>
      </div>
      <div className="card-text-container-title">
        <p className="card-text-container-title-text">Pro Individual</p>
        &nbsp;
        <p className="card-text-container-title-name">-</p>
        &nbsp;
        <p className="card-text-container-title-value">
          {cardDetails.user.proIndividual ? 'Yes' : 'No'}
        </p>
      </div>
      <div className="card-text-container-title">
        <p className="card-text-container-title-text">Pro Entity</p>
        &nbsp;
        <p className="card-text-container-title-name">-</p>
        &nbsp;
        <p className="card-text-container-title-value">
          {cardDetails.user.proEntity ? 'Yes' : 'No'}
        </p>
      </div> */}
    </div>
  )
}

// IT'S A MUST
CustomRevenueCardForUser.propTypes = {
  cardDetails: PropTypes.object,
}

export default CustomRevenueCardForUser
