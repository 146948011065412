import React, { useEffect, useState } from 'react'

import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import CustomTable from 'components/CustomTable/CustomTable'
import { useParams } from 'react-router'
import { REST } from 'utils/axiosHelper'
// STYLE
import './RevenueDistribution.scss'
import loggedIn from 'utils/loggedIn'
import CustomRevenueCard from 'components/CustomRevenueCard/CustomRevenueCard'
import CustomRevenueCardForUser from 'components/CustomRevenueCardForUser/CustomRevenueCardForUser'
import CustomButton from 'components/CustomButton/CustomButton'
import CustomModal from 'components/CustomModal/CustomModal'

const RevenueDistribution = props => {
  const [listOfData, setListOfData] = useState([])
  const [showNoDataText, setShowNoDataText] = useState(false)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [isSubmitAmountEnabled, setIsSubmitAmountEnabled] = useState(false)
  const [amountValue, setAmountValue] = useState(null)
  const { id } = useParams()

  const location = useLocation()

  useEffect(() => {
    getRevenueDistribution()
  }, [])

  useEffect(() => {
    if (amountValue) {
      setIsSubmitAmountEnabled(true)
    } else {
      setIsSubmitAmountEnabled(false)
    }
  }, [amountValue])

  const getRevenueDistribution = async () => {
    await REST.get(`/api/collect/project/getRevenueDistributionForProject?projectId=${id}`)

      .then(answer => {
        console.log(`answer`, answer)
        setShowNoDataText(false)
        setListOfData(answer.data)
        if (answer.data.lenght === 0) {
          setShowNoDataText(true)
        } else {
          setShowNoDataText(false)
        }
      })
      .catch(err => {
        console.log(`err in getRevenueDistribution`, err)
        setShowNoDataText(true)
      })
  }

  const handleAddAmountBtn = () => {
    setModalIsOpen(true)
  }
  const closeModal = () => {
    setModalIsOpen(false)
  }
  const handleSubmitAmount = async () => {
    const body = {
      projectId: id,
      revenueAmount: amountValue,
    }

    await REST.post(`api/collect/project/addRevenueForProject`, body)

      .then(answer => {
        setAmountValue(null)
        setModalIsOpen(false)
        getRevenueDistribution()
        console.log(`answer`, answer)
      })
      .catch(err => {
        console.log(`err in handleSubmitAmount`, err)
      })
  }
  const handleAmountChange = event => {
    setAmountValue(event)
  }
  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      handleSubmitAmount()
    }
  }
  console.log(`amountValue`, amountValue)
  return !loggedIn() ? (
    <Redirect to="/login" />
  ) : (
    <div className="revenueContainer">
      <div className="revenueTitleContainer">
        <h1 className="revenueTitle">
          <span className="revenueTitleRed"> {location.state ? location.state.name : null} </span>-{' '}
          {location.state ? location.state.projectName : 'No data'} -{' '}
          {location.state ? location.state.projectType : null} -{' '}
          {location.state ? location.state.title : null}
        </h1>
        <CustomButton
          name={'addAmount'}
          handleChange={handleAddAmountBtn}
          type="rounded"
          text="Add Amount"
          isEnabled={true}
        />
        <CustomModal
          closeModal={closeModal}
          modalIsOpen={modalIsOpen}
          handleSubmitAmount={handleSubmitAmount}
          handleAmountChange={handleAmountChange}
          handleKeyPress={handleKeyPress}
          isSubmitAmountEnabled={isSubmitAmountEnabled}
          amountValue={amountValue}
          setAmountValue={() => setAmountValue()}
        />
      </div>

      {showNoDataText ? (
        <p className="no-data-text">There is no data to show</p>
      ) : (
        <div className="table-container-for-cards">
          {listOfData.map((item, index) => {
            return (
              <div className="table-row" key={index}>
                <div className="collected-entry-details">
                  <CustomRevenueCardForUser cardDetails={item} />
                </div>
                <div className="arrow-container">
                  <p className="arrow-text">{'>'}</p>
                </div>
                <div className="list-of-cards-container">
                  {item.userRevenueDistributions.map((userItem, userIndex) => {
                    return <CustomRevenueCard cardDetails={userItem} key={userIndex} />
                  })}
                </div>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

// IT'S A MUST
RevenueDistribution.propTypes = {
  className: PropTypes.string, // if it's mandatory prop add ".isRequired"
}

export default RevenueDistribution
//  {mockup.forEach(collectedEntry => (
//     <div className="table-row">
//       <div className="collected-entry-details">
//         {collectedEntry.date} - {collectedEntry.amount}
//       </div>
//       <div className="collected-entry-users-container">
//         {collectedEntry.usersList.map((user,index) => (
//           <CustomRevenueCard cardDetails={user} key={index} />
//         ))}
//       </div>
//     </div>
//   ))}
